// (c) Copyright Ascensio System SIA 2009-2025
//
// This program is a free software product.
// You can redistribute it and/or modify it under the terms
// of the GNU Affero General Public License (AGPL) version 3 as published by the Free Software
// Foundation. In accordance with Section 7(a) of the GNU AGPL its Section 15 shall be amended
// to the effect that Ascensio System SIA expressly excludes the warranty of non-infringement of
// any third-party rights.
//
// This program is distributed WITHOUT ANY WARRANTY, without even the implied warranty
// of MERCHANTABILITY or FITNESS FOR A PARTICULAR  PURPOSE. For details, see
// the GNU AGPL at: http://www.gnu.org/licenses/agpl-3.0.html
//
// You can contact Ascensio System SIA at Lubanas st. 125a-25, Riga, Latvia, EU, LV-1021.
//
// The  interactive user interfaces in modified source and object code versions of the Program must
// display Appropriate Legal Notices, as required under Section 5 of the GNU AGPL version 3.
//
// Pursuant to Section 7(b) of the License you must retain the original Product logo when
// distributing the program. Pursuant to Section 7(e) we decline to grant you any rights under
// trademark law for use of our trademarks.
//
// All the Product's GUI elements, including illustrations and icon sets, as well as technical writing
// content are licensed under the terms of the Creative Commons Attribution-ShareAlike 4.0
// International. See the License terms at http://creativecommons.org/licenses/by-sa/4.0/legalcode

@import "../../styles/variables/index.scss";
@import "../../styles/variables/_colors.scss";
@import "../../styles/_mixins.scss";

:global(.light) {
  :local {
    .socialButton {
      &.isDisabled {
        svg path {
          opacity: 60%;
        }
      }
    }
  }
}

:global(.dark) {
  :local {
    .socialButton {
      svg path {
        fill: #{$white};
      }

      &.isDisabled {
        svg path {
          fill: #{$gray-dark-strong};
        }
      }
    }
  }
}

.socialButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  text-decoration: none;
  padding: 0;
  width: 100%;
  height: 40px;
  text-align: left;
  border-radius: 3px;
  border: var(--social-button-border);
  background: var(--social-button-background);

  touch-callout: none;
  -o-touch-callout: none;
  -moz-touch-callout: none;
  -webkit-touch-callout: none;

  stroke: none;

  &:hover {
    cursor: pointer;
    background: var(--social-button-hover-background);
    border: var(--social-button-hover-border);

    .socialButtonText {
      color: var(--social-button-hover-text-color);

      &.isConnect {
        color: var(--social-button-connect-text-color);
      }
    }
  }

  &.noHover {
    &:hover {
      cursor: default;
      background: var(--social-button-background);
      border: var(--social-button-border);

      .socialButtonText {
        color: var(--social-button-text-color);

        &.isConnect {
          color: var(--social-button-connect-text-color);
        }
      }
    }

    &.isConnect:hover {
      background: var(--social-button-connect-background);
      border: none;
    }
  }

  &:active {
    background: var(--social-button-active-background);
    border: var(--social-button-active-border);
  }

  &:focus {
    outline: none;
  }

  &.isConnect {
    border: none;
    background: var(--social-button-connect-background);

    &:hover {
      cursor: pointer;
      background: var(--social-button-hover-connect-background);
      border: none;
    }

    &:active {
      background: var(--social-button-active-connect-background);
      border: none;
    }

    svg {
      path {
        fill: var(--social-button-connect-svg-fill);
      }
    }
  }

  &.disabled {
    background: var(--social-button-disable-background);
    color: var(--social-button-disable-color);

    &:hover {
      cursor: not-allowed;
      background: var(--social-button-disable-background);
      border: var(--social-button-border);

      .socialButtonText {
        color: var(--social-button-text-color);

        &.isConnect {
          color: var(--social-button-connect-text-color);
        }
      }
    }
  }

  &.small {
    height: 32px;
  }

  svg {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
  }

  &[data-icon-options-color] {
    svg path {
      fill: var(--icon-options-color);
    }
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  pointer-events: none;
}

.socialButtonText {
  position: relative;
  pointer-events: none;
  color: var(--social-button-text-color);
  width: 100%;
  height: 18px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.21875px;
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-inline-end: 16px;

  &.isConnect {
    color: var(--social-button-connect-text-color);
  }
}

.socialButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  gap: 16px;
}
